/* stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type */
@import 'constants';
@import 'utilities';

$border: 1px solid $vue-grey-1;

mat-button-toggle-group.mat-button-toggle-group {

  border: 0;
  border-radius: 0;
  box-shadow: none;

  mat-button-toggle {
    color: $vue-text-grey;
    font-weight: bold;
    font-size: rem(13px);
    border: $border;
    border-right-width: 0;

    &.mat-button-toggle-appearance-standard .mat-button-toggle-button .mat-button-toggle-label-content {
      padding: 0 rem(22px);
      color: $vue-text-grey;
      line-height: rem(40px);
    }

    &.mat-button-toggle-appearance-standard.button-toggle-icon .mat-button-toggle-button .mat-button-toggle-label-content {
      padding: 0 rem(16px);
    }

    button {
      &:focus-visible {
        outline-offset: -2px;
      }
    }
  }

  .mat-button-toggle-checked {
    color: $vue-interaction-teal;
    background-color: $vue-primary-50;
    border-width: 1px;

    &.mat-button-toggle-appearance-standard .mat-button-toggle-button .mat-button-toggle-label-content {
      color: $vue-interaction-teal;
    }
  }

  > :first-child {
    border-radius: $button-border-radius 0 0 $button-border-radius;
  }

  > :last-child {
    border-right-width: 1px;
    border-radius: 0 $button-border-radius $button-border-radius 0;
  }
}

.mat-button-toggle-group-appearance-standard mat-button-toggle.mat-button-toggle + .mat-button-toggle {
  border-left: $border;
}

.mat-button-toggle-group-appearance-standard mat-button-toggle.mat-button-toggle-checked + .mat-button-toggle {
  border-left: 0;
}

// Only two button toggles
mat-button-toggle.mat-button-toggle:nth-child(2):nth-last-child(1) {
  border-left-color: $vue-grey-1;
}

// RTL styles for button-toggle-group
html[dir=rtl] {
  mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    mat-button-toggle {
      border-right-width: 1px;
    }

    > :first-child {
      border-radius: 0 $button-border-radius $button-border-radius 0;
    }

    > :last-child {
      border-left: $border;
      border-radius: $button-border-radius 0 0 $button-border-radius;
    }
  }

  mat-button-toggle.mat-button-toggle:nth-child(1):nth-last-child(2) {
    border-right: $border;
    border-left-width: 0;
  }

  mat-button-toggle.mat-button-toggle:nth-child(2):nth-last-child(1) {
    border-right-width: 0;
    border-left: $border;
  }
}
