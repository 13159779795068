@import 'constants';
@import 'utilities';

.mat-paginator {
  border-top: 1px solid $vue-grey-3;

  .mat-paginator-page-size-label,
  .mat-paginator-range-label {
    color: $vue-text-black;
  }

  .mat-paginator-container {
    padding: 0 $table-horizontal-padding;
  }

  button.mat-icon-button {
    color: $vue-text-grey;

    &.mat-button-disabled {
      color: $vue-grey-2;
    }
  }

  mat-form-field.mat-form-field {
    width: rem(72px);
    margin: 0 rem(12px);

    .mat-form-field-infix {
      padding: rem(8px) 0;
      border-top-width: rem(6px);
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-select-arrow-wrapper {
      transform: translateY(-15%);
    }

    .mat-select-arrow {
      @include custom-arrow(6px);
    }
  }
}
