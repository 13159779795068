// Toast styles
snack-bar-container.mat-snack-bar-container {
  min-width: 400px;
  max-width: 44vw;
  margin: rem(16px);
  padding: rem(16px);
  color: $vue-text-black;
  background: $white;
  border-radius: 12px;
}
