// Dialog styles
$vue-dialog-spacing: 1.5rem;

.vue-dialog {
  mat-dialog-container {
    padding: $vue-dialog-spacing;
    border-radius: 8px;
  }

  .mat-dialog-title {
    margin: 0 (-$vue-dialog-spacing);
    margin-bottom: rem(8px);
    padding: 0 $vue-dialog-spacing $vue-dialog-spacing;
    padding-bottom: 0;
    font-weight: bold;
    font-size: rem(17px);
    line-height: rem(24px);
  }

  .mat-dialog-content {
    margin: 0 (-$vue-dialog-spacing);
    padding: 0 $vue-dialog-spacing;
    font-size: rem(15px);
    line-height: rem(22px);
  }

  .mat-dialog-actions {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    min-height: min-content;
    margin: 0 (-$vue-dialog-spacing);
    padding: $vue-dialog-spacing $vue-dialog-spacing 0;

    vue-basic-button,
    vue-flat-button {
      margin: 0 rem(4px);
    }

    > :last-child {
      margin-right: 0;
    }
  }
}
