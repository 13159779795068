@use 'sass:math';
@import 'colors';

// Convert pixel measurements to rems
@function rem($pixels) {
  @return math.div($pixels, 16px) * 1rem;
}

@mixin top-border-on-scroll($spacing) {
  position: relative;

  &::before {
    position: sticky;
    top: 0;
    z-index: 1;
    display: block;
    height: 1px;
    margin: 0 (-$spacing);
    background: $vue-grey-3;
    content: '';
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 1px;
    background: $white;
    content: '';
  }
}

@mixin uppercase-text {
  color: $vue-text-grey;
  font-weight: 500;   // Matches default material table header
  font-size: rem(11px);
  letter-spacing: rem(1.1px);
  text-transform: uppercase;
}

// Styles to be applied when html direction is rtl
@mixin rtl() {
  [dir=rtl] &,
  ::ng-deep [dir=rtl] & {
    @content;
  }
}

// Custom arrow
@mixin custom-arrow($size: 8px, $degrees: 135deg) {
  width: $size;
  height: $size;
  margin: 0 4px;
  border-top: solid 2px $vue-grey-1;
  border-right: solid 2px $vue-grey-1;
  border-bottom: 0;
  border-left: 0;
  transform: rotate($degrees);
}
