@import '../styles/colors';

// Select / Option styles
.mat-select-panel {
  mat-option {
    color: $vue-text-black;

    &.mat-active:not(.mat-option-disabled) {
      background-color: $vue-background-grey;

      > .mat-option-text {
        font-weight: 600;
      }
    }
  }
}

// Display dropdown of select underneath input element
.select-dropdown {
  margin-top: 2.1rem;
}

.mat-select-value {
  color: $vue-text-black;
}
