/* stylelint-disable selector-no-qualifying-type, selector-max-compound-selectors */

// Show rounded border for last of selection on left
@mixin in-range-left() {
  right: 0;
  left: unset;
  border-left: 1px solid $vue-interaction-teal;
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
}

// Show rounded border for last of selection on right
@mixin in-range-right() {
  border-right: 1px solid $vue-interaction-teal;
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}

// Show rounded border on both right & left side
@mixin single-date-in-range() {
  right: 1px;
  left: 1px;
  border-right: 1px solid $vue-interaction-teal;
  border-left: 1px solid $vue-interaction-teal;
  border-radius: 999px;
}

mat-calendar.mat-calendar {
  height: auto !important;

  // hover and selected state for date picker
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
  .cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: transparent;
    border: 1px solid $vue-interaction-teal;
  }

  .mat-calendar-body-label:not(:last-of-type) {
    visibility: hidden;
  }

  .mat-calendar-body-label:last-of-type {
    display: none;
  }

  .mat-calendar-body-today,
  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border: 0;
  }

  .mat-calendar-body-in-range::before {
    border-top: 1px solid $vue-interaction-teal;
    border-bottom: 1px solid $vue-interaction-teal;
  }

  // set width of in-range first & last pseudo elements
  // Width set to match border radius
  tr > .mat-calendar-body-in-range:last-child,
  tr > .mat-calendar-body-in-range:first-child,
  tr > :not(.mat-calendar-body-in-range) + .mat-calendar-body-in-range:not(:last-child) {
    &::after,
    &::before {
      width: 95%;
    }
  }

  .mat-calendar-arrow {
    @include custom-arrow(rem(7px), 135deg);
    margin: 0 5px 5px;
    fill: white;

    &.mat-calendar-invert {
      margin: 0 5px -2px;
      transform: rotate(-45deg);
    }
  }
}


// LTR styles for in-range pseudo elements
html:not([dir=rtl]) {
  mat-calendar.mat-calendar {
    tr > .mat-calendar-body-in-range:first-child,
    tr > :not(.mat-calendar-body-in-range) + .mat-calendar-body-in-range:not(:last-child) {
      &::after,
      &::before {
        @include in-range-left();
      }
    }

    tr > .mat-calendar-body-in-range:last-child {
      &::after,
      &::before {
        @include in-range-right();
      }
    }

    // First week of the month
    tr > :not(.mat-calendar-body-in-range) + .mat-calendar-body-in-range:last-child,
    // Last week of the month
    tr > .mat-calendar-body-in-range:last-child:first-child {
      &::after,
      &::before {
        @include single-date-in-range();
      }
    }
  }
}

// RTL styles for in-range pseudo elements
// Defining outside of main `mat-calendar` styles to avoid having to override styles from LTR
html[dir=rtl] {
  mat-calendar.mat-calendar {
    tr > .mat-calendar-body-in-range:first-child,
    tr > :not(.mat-calendar-body-in-range) + .mat-calendar-body-in-range:not(:last-child) {
      &::after,
      &::before {
        @include in-range-right();
        left: 0;
      }
    }

    tr > .mat-calendar-body-in-range:last-child {
      &::after,
      &::before {
        @include in-range-left();
      }
    }

    // First week of the month
    tr > :not(.mat-calendar-body-in-range) + .mat-calendar-body-in-range:last-child,
    // Last week of the month
    tr > .mat-calendar-body-in-range:last-child:first-child {
      &::after,
      &::before {
        @include single-date-in-range();
      }
    }
  }
}
