@import './colors';
@import 'utilities';

mat-checkbox {
  .mat-checkbox-inner-container {
    margin-right: rem(12px);
  }

  .mat-checkbox-frame {
    border: solid 2px $vue-grey-1;
    border-radius: rem(2px);
  }

  .mat-checkbox-label {
    white-space: normal;
  }
}
