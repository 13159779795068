@use '@angular/material' as mat;
@import './palettes/index';

$white: #fff;
$black: #000;

$vue-midnight-blue: #003057;

$vue-grey-1: #666;
$vue-grey-2: #c3c3c4;
$vue-grey-3: #dfe1e1;

$vue-text-grey: #0009;
$vue-text-black: $black;

$vue-text-white-1: $white;
$vue-text-white-2: rgba($white, 0.75);

$vue-background-grey: #f5f6f8;
$vue-background-teal: #e0eef2;

// Common palette colors
$vue-primary-50: mat.get-color-from-palette($vue-primary-palette, 50);
$vue-primary-200: mat.get-color-from-palette($vue-primary-palette, 200);
$vue-interaction-teal: mat.get-color-from-palette($vue-primary-palette, 500);
$vue-primary-900: mat.get-color-from-palette($vue-primary-palette, 900);

$vue-success-50: mat.get-color-from-palette($vue-success-palette, 50);
$vue-success-900: mat.get-color-from-palette($vue-success-palette, 900);

$vue-warn-50: mat.get-color-from-palette($vue-warn-palette, 50);
$vue-attention-red: mat.get-color-from-palette($vue-warn-palette, 500);

$vue-accent-500: mat.get-color-from-palette($vue-accent-palette, 500);
