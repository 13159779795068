@import '~directives/background-fade/background-fade.directive';  // Must be imported before vue-styles!
@import '~vue/styles/vue-styles';

/* Global styles */
html,
body {
  height: 100%;
  // Set background color to mask any unforeseen overlaps/overflows
  background-color: $vue-background-grey;
}

body {
  margin: 0;
  color: $vue-text-black;
}

/*
 * Visually hide an element
 * Useful when an element should still be accessible by assistive tools
 */
.visually-hidden {
  position: absolute;
  max-width: 1px;
  max-height: 1px;
  margin: 0;
  overflow: hidden;
}
