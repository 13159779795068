// Tooltip styles
.mat-tooltip-panel {
  mat-tooltip-component {
    .mat-tooltip {
      max-width: rem(400px);
      padding: rem(7px) rem(13px);
      font-size: rem(12px);
      background-color: #434343;
    }
  }
}
