@import './colors';

mat-progress-bar {
  .mat-progress-bar-fill::after {
    background-color: $vue-accent-500;
  }

  .mat-progress-bar-buffer {
    background-color: $vue-background-grey;
  }
}
